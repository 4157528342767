import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Spinner,
  RadioButton,
  Popover,
  Stack,
  ButtonGroup,
} from "@shopify/polaris"
import styled from "styled-components";
import getShopifyFiles from "../../../helpers/getShopifyFiles";
import { set } from "lodash";
const ButtonWrapper = styled.button`
  position: relative;
  border: 0px;
  background-image: linear-gradient(
      45deg,
      rgb(223, 227, 232) 25%,
      rgba(0, 0, 0, 0) 0px,
      rgba(0, 0, 0, 0) 75%,
      rgb(223, 227, 232) 0px,
      rgb(223, 227, 232)
    ),
    linear-gradient(
      45deg,
      rgb(223, 227, 232) 25%,
      rgb(255, 255, 255) 0px,
      rgb(255, 255, 255) 75%,
      rgb(223, 227, 232) 0px,
      rgb(223, 227, 232)
    );
  background-position: 0px 0px, 0.375rem 0.375rem;
  background-size: 0.75rem 0.75rem;
  border-radius: 3px;
  cursor: pointer;
`;
const RadioButtonWrapper = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 1;
`;
const RadioImageWrapper = styled.img`
  border-radius: 3px;
  max-height: 200px;
  max-width: 100%;
`;
const FileNameWrapper = styled.div`
  position: absolute;
  left: 0.25rem;
  bottom: 0.25rem;
  padding: 0.25rem;
  background: rgba(128, 128, 128, 0.65);
  border-radius: 3px;
`
const HorizontalGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
`
export default function ImagesSelector({ token, shop, host, onChange, type }) {
  const [fileID, setFileID] = useState("")
  const [popoverActive, setPopoverActive] = useState(false)
  const [filesData, setFilesData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const run = async () => {
      try{
        const filesResult = await getShopifyFiles({mediaType: type}, token, shop, host)
        console.log("filesResult", filesResult)
        setFilesData(filesResult.data)
      }catch(e){
        console.log(e)
      }
    }
    setIsLoading(true)
    run()
    setIsLoading(false)
  }, []);
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  )

  const handleChange = (_checked, newValue) => setFileID(newValue)
  const imageActivator = (
    <Button onClick={togglePopoverActive}>
      {"IMAGE" === type ? "Select image" : "Select font file"}
    </Button>
  )
  const handleImageSave = () => {
    const file = filesData?.files.edges.find((file) => file.node.id === fileID)
    onChange(file.node)
    setPopoverActive(false)
  }
  return (
    <Popover
      active={popoverActive}
      activator={imageActivator}
      autofocusTarget="first-node"
      onClose={togglePopoverActive}
      fluidContent
    >
      <Popover.Pane fixed>
        <Popover.Section>
          <Stack alignment="trailing" distribution="trailing">
            <ButtonGroup>
              <Button onClick={() => setPopoverActive(false)}>Cancel</Button>
              <Button primary onClick={handleImageSave}>
                Save
              </Button>
            </ButtonGroup>
          </Stack>
        </Popover.Section>
      </Popover.Pane>
      <Popover.Pane>
        <div style={{ width: "650px", padding: "20px" }}>
          <p style={{ marginBottom: "20px" }}>
            {"IMAGE" === type
              ? "You can upload a new image to your store under Content → Files."
              : "You can upload a new font file to your store under Content → Files, the font must be in WOFF or WOFF2 file format"}
          </p>
          {isLoading ? (
            <Spinner accessibilityLabel="Spinner example" size="large" />
          ) : (
            <HorizontalGrid>
              {filesData?.files?.edges.map((file, index) => {
                if (
                  ("GENERIC_FILE" === type &&
                    (file.node.mimeType == "font/woff" ||
                      file.node.mimeType == "font/woff2")) ||
                  ("IMAGE" === type &&
                    file.node.mimeType &&
                    file.node.mimeType.includes("image"))
                ) {
                  return (
                    <ButtonWrapper key={index}>
                      <RadioButtonWrapper>
                        <RadioButton
                          checked={fileID === file.node.id}
                          id={file.node.id}
                          name="files"
                          onChange={handleChange}
                          value={file.node.id}
                        />
                      </RadioButtonWrapper>
                      <RadioImageWrapper
                        src={file.node.preview.image.transformedSrc}
                      />
                      {file.node.url ? (
                        <FileNameWrapper>
                          {file.node.url.split("/").pop().split(".")[0]}
                        </FileNameWrapper>
                      ) : null}
                    </ButtonWrapper>
                  )
                }
                return null
              })}
            </HorizontalGrid>
          )}
        </div>
      </Popover.Pane>
    </Popover>
  )
}
